import './About.css';
import Bottom from './Bottom';
import missionImage from './image/core.svg'; 
import founder from './image/founder.png';
import imglog from './image/img (1).svg';
import Layout from './Layout';

function AboutUs() {
  return (
    <div>
      <Layout />
      <div className="container">
        <div className='judul'>
          <h1>About Us</h1>
        </div>
        <div className="row content-wrapper">
          <div className="col-md-6 text-section">
            <div className="sub">
              <div className='visi'>
                <h3>Vision</h3>
                <p>To lead the way in providing innovative and sustainable Small-Scale LNG solutions, helping Indonesia tap into its underutilized gas resources while supporting the shift towards a greener energy future.</p>
              </div>
              <div className='misi'>
                <h3>Mission</h3>
                <ul>
                  <li><span className="highlight">Maximize Resource, Minimize Waste:</span> Focus on turning marginal and stranded gas assets, along with associated and flare gas to unlock new economic potential.</li>
                  <li><span className="highlight">Seamless Solutions:</span> Provide a complete solution from wellhead to LNG delivery, ensuring a reliable and efficient supply chain.</li>
                  <li><span className="highlight">Innovate and Adapt:</span> Using the latest technology and flexible business model, Blue Energy will provide tailored solutions and business models to fit the specific needs of clients.</li>
                  <li><span className="highlight">Support Sustainability:</span> We're committed to playing our part in reducing emissions and promoting cleaner energy, aligning with Indonesia’s goals for a greener future.</li>
                  <li><span className="highlight">Invest in Communities:</span> We believe in doing business responsibly, ensuring that our work benefits local communities and contributes to their economic and social well-being.</li>
                </ul>
              </div>
            </div>
            <div className="imglog-section">
                <img src={imglog} alt="Community Impact" className="imglog" />
              </div>
          </div>
          <div className="col-md-6 image-section">
            <h3 className="core-value-title">Core Value</h3>
            <img src={missionImage} alt="Mission" className="mission-image" />
            <img src={founder} alt="Founder" className="founder-image" />
          </div>
        </div>
      </div>
      <div className='bottom-page1'>
        <Bottom />
      </div>
    </div>
  );
}

export default AboutUs;
