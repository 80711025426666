import React, { useState, useEffect, useRef } from 'react';
import Bottom from "./Bottom";
import './Home.css';
import bannerImage from './image/banner 1.png';
import mm from './image/banner 2.svg';
import ice from './image/banner 3.svg';
import tes from './image/banner 4.png';
import missionImage from './image/core.svg';
import vidImg from './image/video.svg';
import last from './image/1900.png';
import future from './image/2060.png';
import now from './image/present.png';
import logo from './image/Logo.png';
import Layout from './Layout';
import Modals from './component/Modals';
import Carousel from 'react-bootstrap/Carousel';
import founder from './image/founder.png';
import imglog from './image/img (1).svg';
import VideoModal from './component/VideoModal';

function Home() {
  const [showVideoModal, setShowVideoModal] = useState(false);
  const elementsRef = useRef([]);
  const lngSectionRef = useRef(null);

  useEffect(() => {
    const elements = elementsRef.current;

    const handleIntersection = (entries) => {
      entries.forEach(entry => {
        if (entry.isIntersecting) {
          entry.target.classList.add('visible');
        } else {
          entry.target.classList.remove('visible');
        }
      });
    };

    const observer = new IntersectionObserver(handleIntersection, {
      threshold: 0.1
    });

    elements.forEach(element => {
      if (element) {
        observer.observe(element);
      }
    });

    return () => {
      elements.forEach(element => {
        if (element) {
          observer.unobserve(element);
        }
      });
    };
  }, []);

  const scrollToLngSection = () => {
    if (lngSectionRef.current) {
      lngSectionRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  };


  const handleShowVideoModal = () => {
    setShowVideoModal(true);
  };

  const handleCloseVideoModal = () => {
    setShowVideoModal(false);
  };


  return (
    <div>
      <Layout />
      <div className="home-container">
        <section className="hero-section">
          <div className="hero-text fade-in" ref={el => elementsRef.current.push(el)}>
            <div className="logon">
              <img src={logo} alt="Logon" className="slide-in" ref={el => elementsRef.current.push(el)} style={{ width: "100%", height: "auto" }} />
            </div>
            <h1>Empowering Indonesia's Energy Transition</h1>
            <p>
              We specialize in unlocking the hidden potential of marginal gas fields, stranded assets, and flare gas. Our innovative Small-Scale LNG solutions are designed to create value while supporting Indonesia’s transition to a greener energy future. 
              From upstream handling to downstream distribution, we provide a seamless, integrated approach to maximize your valuable resources.
            </p>
            <div className="hero-button-container">
              <div className='col-md-6'>
                <button className="custom-button" onClick={scrollToLngSection}>Learn More</button>
              </div>
              <div className='col-md-6' >
                <div className='mos' style={{ width: "100%", height: "auto" }}>
                <Modals />
                </div>
              </div>
            </div>
          </div>
          <div className="hero-image">
            <Carousel>
              <Carousel.Item>
                <img src={bannerImage} alt="Banner" className="slide-in" ref={el => elementsRef.current.push(el)} style={{ width: "100%", height: "auto" }} />
              </Carousel.Item>
              <Carousel.Item>
                <img src={mm} alt="Mission" ref={el => elementsRef.current.push(el)} style={{ width: "100%", height: "auto" }}/>
              </Carousel.Item>
              <Carousel.Item>
                <img src={ice} alt="Ice" className="slide-in" ref={el => elementsRef.current.push(el)} style={{ width: "100%", height: "auto" }}/>
              </Carousel.Item>
              <Carousel.Item>
                <img src={tes} alt="tes" className="slide-in" ref={el => elementsRef.current.push(el)} style={{ width: "100%", height: "auto" }}/>
              </Carousel.Item>
            </Carousel> 
          </div>
        </section>
        <section className="mission-section">
          <div className="content-wrapper1">
            <div className="col-md-6 text-section1">
              <div className="sub1">
                <div className='visi1'>
                  <h3>Vision</h3>
                  <p>To lead the way in providing innovative and sustainable Small-Scale LNG solutions, helping Indonesia tap into its underutilized gas resources while supporting the shift towards a greener energy future.</p>
                </div>
                <div className='misi1'>
                  <h3>Mission</h3>
                  <ul>
                    <li><span className="highlight">Maximize Resource, Minimize Waste:</span> Focus on turning marginal and stranded gas assets, along with associated and flare gas to unlock new economic potential.</li>
                    <li><span className="highlight">Seamless Solutions:</span> Provide a complete solution from wellhead to LNG delivery, ensuring a reliable and efficient supply chain.</li>
                    <li><span className="highlight">Innovate and Adapt:</span> Using the latest technology and flexible business model, Blue Energy will provide tailored solutions and business models to fit the specific needs of clients.</li>
                    <li><span className="highlight">Support Sustainability:</span> We're committed to playing our part in reducing emissions and promoting cleaner energy, aligning with Indonesia’s goals for a greener future.</li>
                    <li><span className="highlight">Invest in Communities:</span> We believe in doing business responsibly, ensuring that our work benefits local communities and contributes to their economic and social well-being.</li>
                  </ul>
                </div>
              </div>
              <div className="imglog-section1">
                <img src={imglog} alt="Community Impact" className="imglog1" style={{ width: "100%", height: "auto" }} />
              </div>
            </div>
            <div className="col-md-6 image-section1">
              <h3 className="core1" >Core Value</h3>
              <img src={missionImage} alt="Mission" className="mission-image1" style={{ width: "100%", height: "auto" }} />
              <img src={founder} alt="Founder" className="founder-image1" style={{ width: "100%", height: "auto" }} />
            </div>
          </div>
        </section>
        <section className="lng-section" ref={lngSectionRef}>
          <div className='row'>
            <div className="col-md-6">
              <div className="gas-image slide-in" ref={el => elementsRef.current.push(el)}>
              <img
                  src={vidImg}
                  alt="Gas"
                  style={{ width: "100%", height: "auto" }}
                  onClick={handleShowVideoModal}
                  className="clickable-image"
                />
              </div>
            </div>
            <div className='col-md-6'>
              <div className="lng-text fade-in" ref={el => elementsRef.current.push(el)}>
                <h1>What is LNG?</h1>
              </div>
              <div className="gas-content">
                <div className="gas-text fade-in" ref={el => elementsRef.current.push(el)}>
                  <p>
                    Liquefied Natural Gas (LNG) is natural gas that has been cooled to an extremely low temperature of around -162°C (-260°F), transforming it 
                    from a gas into a liquid state. This process reduces the volume of the gas by approximately 600 times, making it much easier and safer to store 
                    and transport over long distances, particularly where pipelines are not feasible.
                  </p>
                  <p>
                    LNG is primarily composed of methane and is used as a clean and efficient energy source. It is a key player in the global energy market, providing 
                    a versatile and lower-emission alternative to other fossil fuels. Once LNG reaches its destination, it is regasified and distributed to power plants, 
                    industries, and homes for various applications, including electricity generation, heating, and fuelling vehicles.
                  </p>
                  <p>
                    LNG plays a critical role in the energy transition by offering a reliable and more environmentally friendly energy source, 
                    contributing to the reduction of greenhouse gas emissions and supporting the shift towards a sustainable energy future.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="blue-section">
          <div className="blue-text fade-in" ref={el => elementsRef.current.push(el)}>
            <h1>Why Blue?</h1>
          </div>
          <div className="blue-content">
            <div className="blue-left">
              <div className="image-and-line">
                <img src={last} alt="Last" className="slide-in" ref={el => elementsRef.current.push(el)} />
                <div className="vertical-line"></div>
              </div>
              <div className="year-box">
                Early 1990
              </div>
              <div className="vertical-line"></div>
              <div className="card">
                <div className="card-header">
                  <h1>Brown / Grey Energy</h1>
                </div>
                <ul className="list-group list-group-flush">
                  <li className="list-group-item">Most energy is produced using fossil fuels such as coal / petroleum products</li>
                  <li className="list-group-item">Their use has led to high emissions causing the current climate crisis faced by our world</li>
                  <li className="list-group-item">Low Costs and established infrastructure make it attractive</li>
                </ul>
              </div>
            </div>
            <div className="blue-divider"></div>
            <div className="blue-center">
              <div className='image-and-line'>
                <img src={now} alt="Now" className="slide-in" ref={el => elementsRef.current.push(el)} />
                <div className="vertical-line1"></div>
              </div>
              <div className="year-box1">
                Present
              </div>
              <div className="vertical-line1"></div>
              <div className="card">
                <div className="card-header">
                  <h1>Blue Energy</h1>
                </div>
                <ul className="list-group list-group-flush">
                  <li className="list-group-item">Although Natural Gas is considered as a fossil fuel, Natural Gas emit significantly less emission compared to coal 
                    & diesel. Make it suitable replacement for ”conventional” fossil fuels.</li>
                  <li className="list-group-item">We are currently in transition phase to fully replace “conventional” fossil fuel to renewable energy alternative, 
                    Natural Gas would “Filling the Gap” to support the transition phase.</li>
                </ul>
              </div>
            </div>
            <div className="blue-divider1"></div>
            <div className="blue-right">
              <div className='image-and-line'>
                <img src={future} alt="Future" className="slide-in" ref={el => elementsRef.current.push(el)} />
                <div className="vertical-line2"></div>
                <div className="year-box2">
                  2060 Onward
                </div>
              </div>
              <div className="vertical-line2"></div>
              <div className="card">
                <div className="card-header">
                  <h1>Green Energy</h1>
                </div>
                <ul className="list-group list-group-flush">
                  <li className="list-group-item">Energy produced from renewable energy sources such as hydro, solar and wind</li>
                  <li className="list-group-item">Produce almost zero emissions</li>
                  <li className="list-group-item">When fully established, the costs of energy produced by these energy are significantly lower 
                    compared to previous form of energy fuels.</li>
                </ul>
              </div>
            </div>
          </div>
        </section>
        <VideoModal
          show={showVideoModal}
          handleClose={handleCloseVideoModal}
          videoUrl="https://www.youtube.com/embed/uIbgajDzxuc?si=oWkCNPm-V6OPArRZ"
        />
        <div className='bottom'>
          <Bottom />
        </div>
      </div>
    </div>
  );
}

export default Home;
