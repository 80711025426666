import { Route, Routes, BrowserRouter as Router } from 'react-router-dom';
import Home from './pages/Home';
import AboutUs from './pages/AboutUs';
import FAQs from './pages/FAQs';
import Service from './pages/Service';

const App = () => {
   return (
        <>
         <Router>
            <Routes>
               <Route path="/" element={<Home />} />
               <Route path="/aboutUs" element={<AboutUs />} />
               <Route path="/service" element={<Service />} />
               <Route path="/faqs" element={<FAQs />} />
            </Routes>
         </Router>
      </>
   );
};

export default App;
