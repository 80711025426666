import React, { useState } from 'react';
import { NavLink } from 'react-router-dom';
import logo from './image/Logo.png';

function Layout() {
  const [isMenuOpen, setMenuOpen] = useState(false);
  const toggleMenu = () => setMenuOpen(!isMenuOpen);
  
  return (
    <nav className="nav1">
      <div className="logs">
        <div className="logo">
          <img src={logo} alt="BlueEnergy Logo" />
        </div>
        <div className="hamburger" onClick={toggleMenu}>
          <span></span>
          <span></span>
          <span></span>
        </div>
      </div>
      <ul className={isMenuOpen ? 'active' : ''}>
        <li>
          <NavLink exact to="/" activeClassName="active">Home</NavLink>
        </li>
        <li>
          <NavLink to="/aboutUs" activeClassName="active">About Us</NavLink>
        </li>
        <li>
          <NavLink to="/service" activeClassName="active">Services</NavLink>
        </li>
        <li>
          <NavLink to="/faqs" activeClassName="active">FAQs</NavLink>
        </li>
      </ul>
    </nav>
  );
}

export default Layout;
