import { useState } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import 'bootstrap/dist/css/bootstrap.min.css';
import './Modals.css';
import facebookIcon from '../image/FacebookFilled.svg'; 
import linkedinIcon from '../image/LinkedinFilled.svg'; 
import twitterIcon from '../image/TwitterOutlined.svg'; 
import youtubeIcon from '../image/YoutubeFilled.svg';
import locationIcon from '../image/location.png'; 
import mobileIcon from '../image/hp.png';         
import chatIcon from '../image/chat.png';
import map from '../image/Map.png'; 
import Swal from 'sweetalert2';

function Modals({ buttonText = "Contact Us" }) {
  const [lgShow, setLgShow] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();
  
    const fullname = e.target.fullname.value.trim();
    const email = e.target.email1.value.trim();
    const phone = e.target.telp.value.trim();
    const comment = e.target.comment.value.trim();

    if (!fullname || !email || !phone || !comment) {
      Swal.fire({
        title: 'Incomplete Form!',
        text: 'Please fill out all fields before submitting',
        icon: 'warning',
        confirmButtonText: 'OK',
        customClass: {
          confirmButton: 'btn btn-warning'
        },
        buttonsStyling: false,
      });
      return;
    }
  
    const formData = {
      fullname,
      email,
      phone,
      comment,
    };
  
    try {
      const response = await fetch('http://103.65.236.88:3008/api/v2/send_email/blue_energy', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(formData),
      });
  
      const result = await response.json();

      console.log(result)
      if (result.status === 'success') {
        setLgShow(false); 
        Swal.fire({
          title: 'Success!',
          text: 'Message sent successfully',
          icon: 'success',
          confirmButtonText: 'OK',
          customClass: {
            confirmButton: 'btn btn-primary'
          },
          buttonsStyling: false,
        });
      } else {
        Swal.fire({
          title: 'Failed!',
          text: 'Failed to send message Please contact 0812-9600-2885',
          icon: 'error',
          confirmButtonText: 'OK',
          customClass: {
            confirmButton: 'btn btn-danger'
          },
          buttonsStyling: false,
        });
      }
    } catch (error) {
      console.error('Error sending message:', error);
      Swal.fire({
        title: 'Error!',
        text: 'An error occurred while sending the message, Please contact 0812-9600-2885',
        icon: 'error',
        confirmButtonText: 'OK',
        customClass: {
          confirmButton: 'btn btn-danger'
        },
        buttonsStyling: false,
      });
    }
  };
  
  return (
    <>
      <div className="center-button">
        <Button onClick={() => setLgShow(true)}>
        {buttonText}
        </Button>
      </div>
      <Modal
        size="xl" 
        show={lgShow}
        onHide={() => setLgShow(false)}
        aria-labelledby="example-modal-sizes-title-lg"
        className="modal-custom"
      >
        <Modal.Header closeButton>
          <Modal.Title id="example-modal-sizes-title-lg">
            {buttonText === "Get Inquiry" ? "Get In Touch" : "Get In Touch"}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="container">
            <div className="row">
              <div className="col-md-6">
                <h4 className="section-title1">Leave us a message</h4>
                <form onSubmit={handleSubmit}>
                  <div className="form-group">
                    <label htmlFor="fullname">Full Name</label>
                    <input
                      type="text"
                      className="form-control"
                      id="fullname"
                      placeholder="Full Name"
                    />
                  </div>
                  <div className="form-group">
                    <label htmlFor="email1">Email</label>
                    <input
                      type="email"
                      className="form-control"
                      id="email1"
                      placeholder="Email"
                    />
                  </div>
                  <div className="form-group">
                    <label htmlFor="telp">Phone</label>
                    <input
                      type="tel"
                      className="form-control"
                      id="telp"
                      placeholder="Phone"
                    />
                  </div>
                  <div className="formp">
                    <label htmlFor="comment">Comment</label>
                    <textarea
                      className="form-control"
                      id="comment"
                      rows="2"
                      placeholder="Comment"
                    ></textarea>
                  </div>
                  <button type="submit" className="btn btn-secondary">
                    Send
                  </button>
                </form>
              </div>
              <div className="col-md-6">
                <h4 className="section-title">Visit Us</h4>
                <p className="contact-info">
                  <img src={locationIcon} alt="Location" className="icon" />
                  Rukan Crown , Jl. Green Lake City Boulevard No.10, RT.001/RW.010, Petir, Cipondoh, Tangerang City, Banten 15147
                </p>
                <p className="contact-info">
                  <img src={mobileIcon} alt="Mobile" className="icon" />
                  0812-9600-2885
                </p>
                <p className="contact-info">
                  <img src={chatIcon} alt="Email" className="icon" />
                  <a href="mailto:lungga@blueenergy.co.id" className="email-link">lungga@blueenergy.co.id</a>
                </p>
                <div className="map-embed">
                  <a href="https://www.google.com/maps/place/PT.+Wira+Energi/@-6.1940063,106.6997525,17z/data=!3m1!4b1!4m6!3m5!1s0x2e69f11a971c0001:0xd1e070297c80122e!8m2!3d-6.1940063!4d106.7019412!16s%2Fg%2F11c3t5hr6v" target="_blank" rel="noopener noreferrer">
                    <img src={map} alt="Map" style={{ width: '100%', height: 'auto', cursor: 'pointer' }} />
                  </a>
                </div>
                <div className="social-media1">
                  <img src={twitterIcon} alt="Twitter" className="social-icon1" />
                  <img src={facebookIcon} alt="Facebook" className="social-icon1" />
                  <img src={linkedinIcon} alt="LinkedIn" className="social-icon1" />
                  <img src={youtubeIcon} alt="YouTube" className="social-icon1" />
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
}

export default Modals;
