import React from 'react';
import './Service.css';
import Bottom from './Bottom';
import Modals from './component/Modals';
import serviceImg from './image/servvice.svg';
import serImg from './image/service 1.jpg'; 
import serImg1 from './image/service 2.jpg'; 
import serImg2 from './image/service 3.jpeg'; 
import Layout from './Layout';
import Carousel from 'react-bootstrap/Carousel';

function Service() {
  return (
    <div>
      <Layout />
      <div className="container">
        <div className="wrapper">
          <div className="service">
            <div className="service-content">
              <div className="service-text">
                <div className="sub">
                <h1>Our Services</h1>
                  <p>
                    Blue Energy offers a full spectrum of services to unlock the potential of marginal and stranded gas resources. 
                    Our solutions are designed to be efficient, scalable, and tailored to the specific needs of our clients, ensuring value 
                    creation at every stage of the energy supply chain.
                  </p>
                </div>
                <div className="srcImg">
                  <img src={serviceImg} alt="Mission" />
                </div>
                <div className="scp">
              <h1>Scope of Service</h1>
            </div>
              </div>
              <div className="carousel-container">
                <Carousel>
                  <Carousel.Item>
                    <img src={serImg} alt="Service 1" />
                  </Carousel.Item>
                  <Carousel.Item>
                    <img src={serImg1} alt="Service 2" />
                  </Carousel.Item>
                  <Carousel.Item>
                    <img src={serImg2} alt="Service 3" />
                  </Carousel.Item>
                </Carousel>
              </div>
            </div>
            <div className="moda">
              <Modals buttonText="Get Inquiry" />
            </div>
          </div>
        </div>
      </div>
      <div className="bott">
        <Bottom />
      </div>
    </div>
  );
}

export default Service;