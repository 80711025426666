import React from 'react';
import './Bottom.css';
import logo from './image/Logo.png'; 
import facebookIcon from './image/FacebookFilled.svg'; 
import linkedinIcon from './image/LinkedinFilled.svg'; 
import twitterIcon from './image/TwitterOutlined.svg'; 
import youtubeIcon from './image/YoutubeFilled.svg'; 

function Bottom() {
  return (
    <div className="bottom-section">
      <hr className="top-line" />
      <div className="content">
        <div className="text-section">
          <img src={logo} alt="BlueEnergy Logo" className="logo1" />
          <p className="address">
            Rukan Crown, Jl. Green Lake City Boulevard No.10, RT.001/RW.010, Petir, Cipondoh, Tangerang City, Banten 15147
          </p>
        </div>
        <div className="social-media">
          <img src={twitterIcon} alt="Twitter" className="social-icon" />
          <img src={facebookIcon} alt="Facebook" className="social-icon" />
          <img src={linkedinIcon} alt="LinkedIn" className="social-icon" />
          <img src={youtubeIcon} alt="YouTube" className="social-icon" />
        </div>
      </div>
      <hr className="bottom-line" />
      <p className="link">
        &copy; 2024 <a href="https://www.blueenergy.co.id">BlueEnergy</a>
      </p>
    </div>
  );
}

export default Bottom;
