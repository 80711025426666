import React from 'react';
import { Modal } from 'react-bootstrap';
import './VideoModal.css';

function VideoModal({ show, handleClose, videoUrl }) {
  return (
    <Modal show={show} onHide={handleClose} centered size="xl">
      <Modal.Header closeButton>
        <Modal.Title>
         <div className='vid'>What is LNG ?</div> 
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="video-container">
          <iframe
            width="100%"
            height="100%"
            src={videoUrl}
            title="YouTube video player"
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            allowFullScreen
          ></iframe>
        </div>
      </Modal.Body>
    </Modal>
  );
}

export default VideoModal;
