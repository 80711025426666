import 'bootstrap/dist/css/bootstrap.min.css';
import Accordion from 'react-bootstrap/Accordion';
import './FAQs.css';
import Bottom from './Bottom'; 
import Layout from './Layout';

function FAQs() {
  return (
    <div>
      <Layout/>
    <div className="faq-section">
      <h2 className="faq-title">Frequently Asked Questions</h2>
      <Accordion className="accordion-custom">
        <Accordion.Item eventKey="0">
          <Accordion.Header>Why is natural gas better than diesel?</Accordion.Header>
          <Accordion.Body>
            Natural gas is a cleaner, more environmentally friendly source of energy. Compared to diesel, natural gas emits 25% less carbon dioxide (CO2). Carbon dioxide 
            is a greenhouse gas that traps heat in our atmosphere and contributes to global warming.
            <br/>
            Natural gas also eliminates a number of harmful air pollutants including nitrogen oxide (NOx), sulfur dioxide (SO2), 
            lead(Pb), carbon monoxide (CO), and fine particels (PM2.5). These air pollutants are now recognized as contributors to lung and heart diseases. In
            addition, Natural gas is more economically friendly and can cost up to 60% less than diesel.
          </Accordion.Body>
        </Accordion.Item>
        <Accordion.Item eventKey="1">
          <Accordion.Header>Why is natural gas better than propane?</Accordion.Header>
          <Accordion.Body>
          Natural gas is cleaner than propane. The combustion of propane releases a higher content of carbon dioxide (CO2), 
          which is a greenhouse gas (GHGs), than the combustion of methane. In addition, the use of propane involves the possibility 
          of emitting nitrogen oxide (NOx) or non-methane hydrocarbons (NMHC) and carbon monoxide (CO), which are harmful air pollutants. 
          Natural gas is also up to 40% less costly than propane. For many of the same reasons natural gas is preferred over diesel, natural gas is preferred over propane.
          </Accordion.Body>
        </Accordion.Item>
        <Accordion.Item eventKey="2">
          <Accordion.Header>Why is LNG better or more efficient than CNG?</Accordion.Header>
          <Accordion.Body>
          LNG contains 600 times more energy in the same amount of space as compared to natural gas. CNG contains approximately 60 times more energy. 
          This makes LNG more efficient by volume and reduces the number of trucks on the road to transport the same amount of gas. Additionally, 
          LNG is transported safely at low pressure and is easier and more economical to store.
          </Accordion.Body>
        </Accordion.Item>
        <Accordion.Item eventKey="3">
          <Accordion.Header>Is your LNG cleaner than natural gas pipeline?</Accordion.Header>
          <Accordion.Body>
          Actually, yes, 3-5% methane leaks into the air when using traditional gas pipelines.
          Edge mobile solution, which liquefies gas right at the wellhead, eliminates traditional 
          gas pipelines and therefore removes any methane leaks related to them.
          </Accordion.Body>
        </Accordion.Item>
      </Accordion>
      <div className='bottom-page'><Bottom /></div>
    </div>
    </div>
  );
}

export default FAQs;
